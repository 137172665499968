<template>
  <v-container fluid>
    <v-row content="center" align-content="center">
      <v-col align="center">
        <v-progress-circular
          :indeterminate="!showProgress"
          :value="progress"
          :rotate="-90"
          color="primary"
          size="100"
          width="15"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        {{ $t('Synchronization in progress...') }}
        <!-- {{ progress }}% -->
        <!-- {{ stepsFinished }} / {{ stepsCount }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SyncProgress',

  computed: {
    ...mapState({
      stepsCount: state => state.storage.stepsCount,
      stepsFinished: state => state.storage.stepsFinished
    }),

    showProgress () {
      return this.stepsCount > 0 && this.stepsFinished > -1
    },

    progress () {
      if (!this.showProgress) {
        return 0
      }

      const progress = this.stepsFinished / this.stepsCount

      return (progress <= 1 ? progress : 1) * 100
    }
  }
}
</script>
