<template>
  <v-container>
    <v-row v-if="this.error" content="center" align-content="center">
      <v-col class="align-center content-center justify-center">
        <v-icon color="red" x-large class="mb-4">
          mdi-alert-circle
        </v-icon>

        <h1>{{ error }}</h1>
      </v-col>
    </v-row>
    <v-row v-else content="center" align-content="center">
      <v-col class="align-center content-center justify-center">
        <v-icon color="primary" x-large class="mb-4">
          mdi-sync-circle
        </v-icon>

        <h1>{{ $t(`Synchronization`) }}</h1>
      </v-col>
    </v-row>

    <v-row v-if="!hasSync">
      <v-col class="align-center content-center justify-center">
        <p>{{ $t('Before running the app, you have to synchronize with cloud data') }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="align-center content-center justify-center">
        <v-btn
          elevation="0"
          color="primary"
          x-large
          @click="startSync"
        >
          {{ $t('Synchronize') }}
        </v-btn>

        <v-btn
          class="ml-3"
          v-if="hasSync"
          elevation="0"
          color="secondary"
          x-large
          @click="closeSync"
        >
          {{ $t('Sync later') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SyncAlert',

  props: {
    error: {
      type: String
    },

    startSync: {
      type: Function,
      required: true
    },

    closeSync: {
      type: Function,
      required: true
    },

    hasSync: {
      type: Boolean,
      required: true
    }
  }
}
</script>
