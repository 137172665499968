<template>
  <div>
    <v-snackbar
      v-if="!appState.synchronizing"
      v-model="alert"
      absolute
      bottom
      color="success"
      outlined
    >
      {{ $t('Synchronization finished') }}
    </v-snackbar>

    <v-container v-if="appState.mustCharge">
      <v-row content="center" align-content="center">
        <v-col align="center">
          <v-icon x-large color="red">
            mdi-battery-off
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <h1>{{ $t('Battery is too low') }}</h1>
          <p>{{ $t('This app works mostly offline, but your device should keep the minimum battery level') }}</p>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="appState.mustConnect && !appState.isConnected">
      <v-row content="center" align-content="center">
        <v-col align="center">
          <v-icon x-large color="red">
            mdi-signal-off
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <h1>{{ $t('No internet connection') }}</h1>
          <p>{{ $t('Internet connection is required for this app to initialize') }}</p>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <div style="width: 100%; height: 100%;" v-if="!inSync">
        <router-view />
      </div>

      <div v-else>
        <v-card class="transparent" align="center" elevation="0">
          <sync-alert
            v-if="!appState.synchronized && !appState.synchronizing"
            :start-sync="startSync"
            :close-sync="closeSync"
            :error="error"
            :has-sync="!shouldSync"
          />

          <sync-progress v-else />
        </v-card>
      </div>
    </template>
  </div>
</template>

<script>
import SyncAlert from '@/components/sync-alert'
import SyncProgress from '@/components/sync-progress'

export default {
  name: 'Event',

  components: {
    SyncAlert,
    SyncProgress
  },

  data: () => ({
    alert: false,
    justSynchronized: false,
    preflight: null,
    error: null
  }),

  computed: {
    shouldSync () {
      return this.appState.mustUpload || this.appState.mustDownload
    },

    inSync () {
      return this.appState.showSyncWrapper || this.appState.synchronizing
    },

    checked () {
      return !!this.preflight
    },

    noInternet () {
      return !this.appState.isConnected
    },

    batteryLow () {
      if (this.$nosework.context.device().platform === 'web' || this.$nosework.context.device().platform === 'electron') {
        return false
      }

      return this.$isCharging !== true && this.$batteryLevel <= 0.3
    }
  },

  watch: {
    'appState.showSyncWrapper'(show) {
      if (show) {
        this.startSync()
      }
    }
  },

  beforeMount () {
    if (this.appState.synchronized) {
      this.closeSync()
    }

    this.$nosework.context.on('synchronized', true, () => {
      this.closeSync()
    })
  },

  mounted () {
    this.preflight = this.$nosework.context.preflight()

    if (!this.appState.isConnected) {
      this.startSync()
    }

    if (this.inSync) {
      this.$store.commit('setNavigation', { show: false })
    }
  },

  methods: {
    startSync () {
      this.error = '';

      this.appState.synchronizing = true

      this.$nosework.context.storage().synchronize().then(() => {
        this.appState.showSyncWrapper = false
        this.alert = true
      }).catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 403:
            case 401:
              this.appState.synchronizing = false
              this.appState.showSyncWrapper = false
              this.$nosework.context.auth().logout()
              return
            default:
              this.error = err.message
          }
        } else {
          this.error = err.message
        }

        this.appState.synchronizing = false
        this.appState.showSyncWrapper = true
      })
    },

    closeSync () {
      this.justSynchronized = false
      this.appState.showSyncWrapper = false
    }
  }
}
</script>
